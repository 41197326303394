<template>
	<main class="cet4-container">
        <section class="flex-center">
            <a-select v-model:value="curSelect" style="width: 300px">
                <a-select-option  v-for="(item, i) of selects" :key="i" :value="i">{{item.label}}</a-select-option>
            </a-select>
            <!-- <a-radio-group v-model:value="cetValue">
                <a-radio-button value="CET4">CET4</a-radio-button>
            </a-radio-group>
            <span class="mlr10 flex-align-center">-</span>
             <a-radio-group v-model:value="year">
                <a-radio-button :value="year" v-for="(year) of [2020, 2019]" :key="year">{{year}}</a-radio-button>
            </a-radio-group>
            <span class="mlr10 flex-align-center">-</span>
            <a-radio-group v-model:value="month">
                <a-radio-button v-for="month of months" :key="month" :value="month">{{ month }}月</a-radio-button>
            </a-radio-group>
            <span class="mlr10 flex-align-center">-</span>
            <a-radio-group v-model:value="index">
                <a-radio-button :value="1">第一套</a-radio-button>
                <a-radio-button :value="2">第二套</a-radio-button>
                <a-radio-button :value="3">第三套</a-radio-button>
            </a-radio-group>
            <span class="mlr10 flex-align-center">-</span>
            <a-button type="primary" @click="search">查询</a-button> -->
        </section>
        <a-spin :spinning="curSearch.isLoading">
            <a-divider class="mt20"><span class="cet-title">{{ curSearch.year }}年 {{curSearch.month}}月大学英语四级真题（第{{curSearch.index}}套）</span></a-divider>
            <TplCet4 :year="curSearch.year" :month="curSearch.month" :index="curSearch.index" />
        </a-spin>
        <!-- 广告部分 -->
        <!-- <section class="ad-wrap">
            <div class="title">听力材料</div>
            <section class="ad-item">
                <img src="https://cdn.amathclass.cn/shuting/shuting_erweima.jpg" alt="">
            </section>
            <section class="ad-item">
                <img src="https://cdn.amathclass.cn/sti/sti_erweima.jpg" alt="">
            </section>
            <div class="footer">微信扫一扫噢！</div>
        </section> -->
        <div style="color: #4a4a4a; font-size: 16px; text-align: center; cursor: pointer; padding-bottom: 48px;" @click="beian">Copyright © 2021 izy. All Right Reserved. | 备案号：赣ICP备 20000637号-2</div>
    </main>
</template>

<script>
import { computed, ref, watch } from 'vue';
export default {
    name: 'Index',
    setup() {
        const cetValue = ref('CET4');
        const tabs = ref(['第一套', '第二套', '第三套']);
        const activeKey = ref(tabs.value[1]);
        const selects = ref([
            { label: '2020年12月 · 第 I 套', year: 2020, month:12, index: 1 },
            { label: '2020年09月 · 第 I 套', year: 2020, month:9, index: 1 },
            { label: '2020年09月 · 第 II 套', year: 2020, month:9, index: 2},
            { label: '2020年07月 · 第 I 套', year: 2020, month:7, index: 1 },
            { label: '2019年12月 · 第 I 套', year: 2019, month:12, index: 1 },
            { label: '2019年12月 · 第 II 套', year: 2019, month:12, index: 2 },
            { label: '2019年12月 · 第 III 套', year: 2019, month:12, index: 3 },
            { label: '2019年06月 · 第 I 套', year: 2019, month:6, index: 1 },
            { label: '2019年06月 · 第 II 套', year: 2019, month:6, index: 2 },
            { label: '2019年06月 · 第 III 套', year: 2019, month:6, index: 3 },
        ])
        const curSelect = ref(0);
        const year = ref(2020);
        const month = ref(12);
        const index = ref(1);
        const months = ref([12, 9, 7]);
        // const curSearch = reactive({})
        const curSearch = computed(() => {
            const item = selects.value[curSelect.value];
            return {
                isLoading: false,
                year: item.year,
                month: item.month,
                index: item.index
            };
        })

        watch(year, (newYear) => {
            if (newYear === 2020) months.value = [12, 9, 7];
            else months.value = [12, 6];
            month.value = months.value[0];
        })
        // const search = () => {
        //     curSearch.isLoading = true;
        //     curSearch.year = year.value;
        //     curSearch.month = month.value;
        //     curSearch.index = index.value;
        //     setTimeout(() => {
        //         curSearch.isLoading = false;
        //     }, 1000)
        // }
        // search();
        const beian = () => window.location.href = 'https://beian.miit.gov.cn';
        return { curSelect, selects, month, months,index, cetValue, tabs, year, activeKey, curSearch, beian };
    }
}
</script>

<style lang="less" scoped>
.cet4-container {
    height: 100vh;
    overflow: scroll;
    background: #fff;
}
.cet-title {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}
.ad-wrap {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 180px;
    background: #fcfcfc;
    .title {
        line-height: 36px;
        text-align: center;
        border-bottom: 1px solid #eee;
        color: #000;
        font-weight: bold;
    }
    .ad-item {
        padding: 16px 24px;
        width: 100%;
        position: relative;
        img {
            width: 100%;
        }
        border-bottom: 1px solid #eee;
    }
    .footer {
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        color: #777;
    }
}
</style>
